<template>
    <div class="two-one page-layout">
        <div class="slot">
            <slot name="slot1" />
        </div>
        <div class="slot">
            <slot name="slot2" />
        </div>
        <div class="slot">
            <slot name="slot3" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TwoOneLayout'
};
</script>

<style lang="scss" scoped>
.two-one {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .slot {
        width: 50%;
        padding: 15px 30px;
        &:last-of-type {
            padding: 30px 0;
            width: 100%;
        }
    }
}
</style>